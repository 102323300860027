.header h4 {
    font-weight: 300;
    font-size: 20px;
   margin-left:-225px;
   
  }
  .images img {
    
   margin-left:-290px;
   margin-top:-95px;
   
  }
  .absolute1 {
    margin-left: -100px;
  }
  .absolute {
    margin-bottom: 550px;
  }

  .wrapper {
    margin-top: 0px;
  }

  .headline {
    margin-top:3px;
    color:black;
 
  }
  .mt-5 {
    color:black;
    font-weight: 600;
    font-size: 19px;
  }
  .mt-3 {
    color:black;
    font-weight: 400;
  }

  .feature1 {
    margin-left: -600px;
    
  }
  .feature2 h1{
    color:black;
    margin-left: 400px;
    font-weight: 900;
    font-size: 30px;
    
  }
  .feature2 h2{
    color:black;
    margin-left: 400px;
    font-weight: 200;
    font-size: 19px;
    
    
  }
  .feature2 button{
    margin-left: 400px;
   
    
  }
  .feature2 {
    margin-top: -20px;
    
  }
  .feature1 h1 {
    color:black;
    font-weight: 900;
    font-size: 30px;
    
  }
  .feature3 p{
    color:black;
    margin-top: 100px;
  }
  .feature4 {
    margin-left: -600px;
    margin-top: -100px;
    
  }
  .feature5 h1{
    color:white;
    margin-left: 400px;
    font-weight: 900;
    font-size: 30px;
    
  }
  .feature5 h2{
    color:white;
    margin-left: 400px;
    font-weight: 200;
    font-size: 19px;
    
    
  }
  .feature5 button{
    margin-left: 400px;
   
    
  }
  .feature5 {
    margin-top: -20px;
    
  }
  .feature4 h1 {
    color:white;
    font-weight: 900;
    font-size: 30px;
    
  }
  .feature6p{
    color:white;
    margin-top: 100px;
  }

  .header {
    background-color: #081730;
    -ms-flex-item-align: center;
    justify-items: center;
  }